import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Org } from '../../../core/models/org.model';
import { TrackingService } from '../../../core/services/tracking.service';

@Component({
  selector: 'app-active-org-card',
  templateUrl: './active-org-card.component.html',
  styleUrls: ['./active-org-card.component.scss'],
})
export class ActiveOrgCardComponent implements OnInit {
  @Input({ required: true }) activeOrg: Org;

  @Input({ required: true }) isPrimary: boolean;

  @Input({ required: true }) isLoading: boolean;

  @Output() activeOrgClick: EventEmitter<Org> = new EventEmitter<Org>();

  constructor(private trackingService: TrackingService) {}

  ngOnInit() {}

  onOrgSelection() {
    this.trackingService.activeOrganizationDisplayCard();
    this.activeOrgClick.emit();
  }
}
