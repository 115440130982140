import { Component, OnInit, Input } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DialogTheme } from '../dialog/dialog-theme.enum';

@Component({
  selector: 'app-dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.scss'],
})
export class DialogHeaderComponent implements OnInit {
  @Input() titleTheme: DialogTheme;

  @Input() title: string;

  dialogThemes: typeof DialogTheme = DialogTheme;

  constructor(private ref: DynamicDialogRef) { }

  close(action: string) {
    this.ref.close(action);
  }

  ngOnInit() {}
}
