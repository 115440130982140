import { Injectable } from '@angular/core';
import { User } from '../models/user.model';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { UserPasswordStatus } from '../models/user-password-status.model';
import { OrgUserStatus } from '../models/org-user-status.enum';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private authService: AuthService, private apiService: ApiService) {}

  postUser(user: User): Observable<User> {
    return this.apiService.post<User>('/users', user);
  }

  isPendingDetails(): boolean {
    const eou = this.authService.getEou();
    return eou.ou.status === OrgUserStatus.PENDING_DETAILS;
  }

  isActive(): boolean {
    const eou = this.authService.getEou();
    return eou.ou.status === OrgUserStatus.ACTIVE;
  }

  isDisabled(): boolean {
    const eou = this.authService.getEou();
    return eou.ou.status === OrgUserStatus.DISABLED;
  }

  getUserPasswordStatus(): Observable<UserPasswordStatus> {
    return this.apiService.get<UserPasswordStatus>('/users/password_required');
  }
}
