/**
 * This directive serves as a wrapper around PrimeNG's Tooltip component.
 * Utilizing composition, it embeds the functionality of PrimeNG's Tooltip
 * within our custom FdlTooltipDirective. This setup allows us to interact
 * with the Tooltip's lifecycle hooks and manage its behavior in a way that
 * suits our application's needs.
 *
 * @example:
 * <div fdlTooltip="Tooltip content" tooltipDirection="bottom" tooltipAlignment="right">Test</div>
 */

import {
  Directive,
  Input,
  ElementRef,
  Renderer2,
  NgZone,
  PLATFORM_ID,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Tooltip } from 'primeng/tooltip';
import { PrimeNGConfig } from 'primeng/api';

/**
 * Factory function to create an instance of Tooltip with necessary dependencies.
 *
 * @param {Object} platformId - Platform identifier to check platform-specific behaviors.
 * @param {ElementRef} elm - Reference to the host element.
 * @param {NgZone} zone - Angular's execution context.
 * @param {PrimeNGConfig} config - Configuration for PrimeNG components.
 * @param {Renderer2} renderer - Angular's renderer to modify DOM elements.
 * @param {ViewContainerRef} vc - Reference to the view container of the component.
 *
 * @returns {Tooltip} - An instance of Tooltip.
 */
// eslint-disable-next-line max-params-no-constructor/max-params-no-constructor
function TooltipDirectiveFactory(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  platformId: any,
  elm: ElementRef,
  zone: NgZone,
  config: PrimeNGConfig,
  renderer: Renderer2,
  vc: ViewContainerRef,
): Tooltip {
  return new Tooltip(platformId, elm, zone, config, renderer, vc);
}

/**
 * Provider object for Tooltip, utilizing the TooltipDirectiveFactory for instantiation.
 */
const TooltipDirectiveProvider = {
  provide: Tooltip,
  useFactory: TooltipDirectiveFactory,
  deps: [PLATFORM_ID, ElementRef, NgZone, PrimeNGConfig, Renderer2, ViewContainerRef],
};

@Directive({
  selector: '[fdlTooltip]',
  providers: [TooltipDirectiveProvider],
  standalone: true,
})
export class FdlTooltipDirective implements AfterViewInit, OnChanges {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input('fdlTooltip') content: string | TemplateRef<any>;

  /**
   * The direction in which the tooltip will be displayed, valid values are "left", "right", "top" and "bottom".
   */
  @Input() tooltipDirection: 'left' | 'right' | 'top' | 'bottom';

  /**
   * The alignment of the tooltip arrow, valid values are "left", "right" and "center".
   */
  @Input() tooltipAlignment: 'left' | 'right' | 'center';

  /**
   * Optional style class to add to the tooltip.
   */
  @Input() tooltipCustomStyleClass: string = '';

  /**
   * Hides the tooltip if its value is set to true.
   */
  @Input() tooltipDisabled: boolean;

  /**
   * Optional Z-Index for the tooltip.
   */
  @Input() tooltipCustomZIndex: string;

  /**
   * By default the tooltip contents are rendered as text. Set to false to support html tags in the content.
   */
  @Input() tooltipEscape: boolean;

  /**
   * Event to trigger the tooltip, valid values are "hover" and "focus".
   * Default is "hover".
   */
  @Input() tooltipShowEvent: string;

  /**
   * Whether to hide tooltip when hovering over tooltip content.
   */
  @Input() tooltipAutohide: boolean;

  @Input() tooltipHideDelay: number;

  @Input() tooltipShowDelay: number;

  defaultTooltipStyleClass: string = 'fdl-tooltip';

  constructor(private tooltip: Tooltip) {}

  /**
   * Updates the tooltip options based on changes in input properties. Utilizes the `setOption` method from PrimeNG's Tooltip to set various tooltip variables.
   * If the `tooltipAlignment` input property has changed, additional custom classes are appended to `tooltipStyleClass` to adjust the alignment of the tooltip arrow based on the specified alignment value.
   * Other tooltip options are set based on the values of the `content` and `tooltipDirection` input properties.
   */
  private updateTooltipOptions(changes: SimpleChanges): void {
    let tooltipCustomStyleClass = `${this.defaultTooltipStyleClass} ${this.tooltipCustomStyleClass}`;

    if (changes.content) {
      this.tooltip.setOption({ tooltipLabel: this.content });
    }

    if (changes.tooltipDirection) {
      this.tooltip.setOption({ tooltipPosition: this.tooltipDirection });
    }

    if (changes.tooltipDisabled) {
      this.tooltip.setOption({ disabled: this.tooltipDisabled });
    }

    if (changes.tooltipCustomZIndex) {
      this.tooltip.setOption({ tooltipZIndex: this.tooltipCustomZIndex });
    }

    if (changes.tooltipShowEvent) {
      this.tooltip.setOption({ tooltipEvent: this.tooltipShowEvent });
    }

    if (changes.tooltipEscape) {
      this.tooltip.setOption({ escape: this.tooltipEscape });
    }

    if (changes.tooltipAutohide) {
      this.tooltip.setOption({ autoHide: this.tooltipAutohide });
    }

    if (changes.tooltipHideDelay) {
      this.tooltip.setOption({ hideDelay: this.tooltipHideDelay });
    }

    if (changes.tooltipShowDelay) {
      this.tooltip.setOption({ showDelay: this.tooltipShowDelay });
    } else {
      this.tooltip.setOption({ showDelay: 500 });
    }

    if (changes.tooltipAlignment) {
      const alignmentClass = ` fdl-tooltip-align-${this.tooltipAlignment}`;
      tooltipCustomStyleClass += alignmentClass;
    }
    this.tooltip.setOption({ tooltipStyleClass: tooltipCustomStyleClass });
  }

  show() {
    this.tooltip.show();
  }

  hide() {
    this.tooltip.hide();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateTooltipOptions(changes);
    this.tooltip.ngOnChanges(changes);
  }

  ngAfterViewInit(): void {
    this.tooltip.ngAfterViewInit();
  }
}
