import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Cacheable } from 'ts-cacheable';
import { AuthResponse } from '../models/auth-response.model';
import { HttpRequestOptions } from '../models/http-request-options.model';
import { OrgPayload } from '../models/org-payload.model';
import { Org } from '../models/org.model';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { TabStorageService } from './tab-storage.service';

@Injectable({
  providedIn: 'root',
})
export class OrgService {
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private tabStorageService: TabStorageService
  ) {}

  @Cacheable()
  getCurrentOrg(): Observable<Org> {
    const queryParams: OrgPayload = {
      is_current: true,
    };
    const data: HttpRequestOptions = {
      params: {
        ...queryParams,
      },
    };
    return this.apiService.get<Org[]>('/orgs', data).pipe(map((orgs) => orgs[0]));
  }

  @Cacheable()
  getOrgs(): Observable<Org[]> {
    return this.apiService.get<Org[]>('/orgs');
  }

  getPrimaryOrg(): Observable<Org> {
    const queryParams: OrgPayload = {
      is_primary: true,
    };

    const data: HttpRequestOptions = {
      params: {
        ...queryParams,
      },
    };
    return this.apiService.get<Org>('/orgs', data);
  }

  switchOrg(orgId: string): Observable<string> {
    return this.apiService.post<AuthResponse>(`/orgs/${orgId}/refresh_token`).pipe(
      switchMap((res) => {
        this.tabStorageService.set('org_id', orgId);
        return this.authService.getNewAccessToken(res.refresh_token);
      })
    );
  }
}
