@if (displayAlert) {
  <div class="alert-container" [@fadeAndCollapseOnLeave] data-testId="alert-message"
    [ngClass]="{'info-container': type === alertTypes.INFORMATION, 'warning-container': type === alertTypes.WARNING, 'error-container': type === alertTypes.ERROR, 'success-container': type === alertTypes.SUCCESS}">
    <div class="tw-flex tw-w-full tw-items-center">
      @if (showIcon) {
        @switch (type) {
          @case (alertTypes.INFORMATION) {
            <ui-svg-sprite [icon]="'info-circle-fill'" class="icon tw-fill-bg-info"></ui-svg-sprite>
          }
          @case (alertTypes.WARNING) {
            <ui-svg-sprite [icon]="'warning-fill'" class="icon tw-fill-bg-warning"></ui-svg-sprite>
          }
          @case (alertTypes.ERROR) {
            <ui-svg-sprite [icon]="'danger-fill'" class="icon tw-fill-bg-danger"></ui-svg-sprite>
          }
          @case (alertTypes.SUCCESS) {
            <ui-svg-sprite [icon]="'check-circle-fill'" class="icon tw-fill-bg-success"></ui-svg-sprite>
          }
        }
      }
      @if (message) {
        <div>
          @if (header) {
            <div class="message header" [innerHTML]="header"></div>
          }
          <div class="message" [innerHTML]="message"></div>
        </div>
      } @else {
        <div class="message tw-w-full">
          <ng-content></ng-content>
        </div>
      }
    </div>
    <div class="tw-flex">
      @if (dismissible) {
        <ui-svg-sprite (click)="dismissAlert()" [icon]="'cross'" class="icon-cross"></ui-svg-sprite>
      }
    </div>
  </div>
}