import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';

export const AuthGuard = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const eou = authService.getEou();
  if (!eou || !authService.isLoggedIn()) {
    router.navigate(['signin']);
    return false;
  }
  return true;
};
