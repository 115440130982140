import { Inject, Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-localstorage';
import { TabStorageService } from './tab-storage.service';

@Injectable({
  providedIn: 'root',
})
export class OrgStorageService {
  constructor(
    private localStorageService: LocalStorageService,
    private tabStorageService: TabStorageService,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private findActiveOrgId(orgData: Record<string, any>): string | null {
    for (const orgId in orgData) {
      if (orgData[orgId] && orgData[orgId].refresh_token && orgData[orgId].access_token && orgData[orgId].user) {
        return orgId;
      }
    }
    return null;
  }

  set(key: string, value: any) {
    const sessionOrgId = this.tabStorageService.get('org_id');

    /* Additional fail safe condition to avoid corrupting local storage data. */
    if (!sessionOrgId) {
      return;
    }

    let orgData = this.localStorageService.get('org_data');

    /* If setting the `orgData` for the first time */
    if (!orgData) {
      orgData = {};
    }

    /* If setting the data against the org id for the first time */
    if (!orgData[sessionOrgId]) {
      orgData[sessionOrgId] = {};
    }
    orgData[sessionOrgId][key] = value;
    this.localStorageService.set('org_data', orgData);
  }

  get(key: string): any {
    const sessionOrgId = this.tabStorageService.get('org_id');
    const orgData = this.localStorageService.get('org_data');
    /*
     * If there's an org data with having refresh_token, access_token and user key and no session org_id,
     * then the user has opened a new tab. In this case, the user should be taken to the switch_org page.
     */
    if (orgData && !sessionOrgId) {
      const keys = Object.keys(orgData);
      if (!keys?.length) {
        return null;
      }
      /*
       * In case of more than one orgId present in orgData, we cannot decide on which of the orgId user has loggedIn
       * So, we need to explicitly check for activeOrgId which has refresh_token , access_token and user key
       * then we set the same orgId to tabStorage.
       */
      const activeOrgId = this.findActiveOrgId(orgData);
      if (activeOrgId) {
        this.tabStorageService.set('org_id', activeOrgId);
        return orgData[activeOrgId] ? orgData[activeOrgId][key] : null;
      }

      return null;
    }

    /* additional failsafe condition. */
    return orgData && orgData[sessionOrgId] ? orgData[sessionOrgId][key] : null;
  }

  remove(key: string) {
    if (key === 'org_data') {
      this.localStorageService.remove('org_data');
    } else {
      /**
       * Ideally we should be deleting the entire org data instead of deleting a key from a particular org.
       * Will there be a scenario where we need to delete a key from a particular org?
       */
      const sessionOrgId = this.tabStorageService.get('org_id');
      const orgData = this.localStorageService.get('org_data');
      if (orgData && orgData[sessionOrgId]) {
        delete orgData[sessionOrgId][key];
        this.localStorageService.set('org_data', orgData);
      }
    }
  }

  removeKeysOnLogout() {
    const orgData = this.localStorageService.get('org_data');
    const orgLevelLocalStorageKeys = [
      'access_token',
      'refresh_token',
      'user',
      'in_app_chat_restore_id',
      'lv_ui_grid_pagination_page_number',
      'ui_grid_pagination_page_number',
      'last_logged_in_delegatee',
      'current_view',
      'ui_grid_pagination_recurrences_page_number',
    ];
    for (const orgId in orgData) {
      for (const key of orgLevelLocalStorageKeys) {
        /**
         * No need to check for key is present or not in orgData[orgId] because the keys we have decided to remove, May
         * come as null or undefined in orgData[orgId] in some cases but we want to remove the key in these case as well.
         * So, There is no meaning for checking of null or unidefined key.
         */
        delete orgData[orgId][key];
      }
    }
    this.localStorageService.set('org_data', orgData);
  }
}
