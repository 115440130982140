import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, finalize, throwError } from 'rxjs';
import { RouterAuthService } from '../../core/services/router-auth.service';
import { SecondaryEmailQueryParams } from './secondary-email-query-params.interface';

@Component({
  selector: 'app-secondary-email',
  templateUrl: './secondary-email.component.html',
  styleUrls: ['./secondary-email.component.scss'],
})
export class SecondaryEmailComponent implements OnInit {
  isError: boolean;

  isSecondaryEmailVerified: boolean;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private routerAuthService: RouterAuthService
  ) {}

  private waitAndGotoSignInPage() {
    setTimeout(() => {
      this.router.navigate(['signin']);
    }, 3000);
  }

  ngOnInit() {
    const queryParams = this.activatedRoute.snapshot.queryParams as SecondaryEmailQueryParams;

    this.routerAuthService
      .verifySecondaryEmail(queryParams.email_id)
      .pipe(
        catchError((error) => {
          this.isError = true;
          return throwError(() => error);
        }),
        finalize(() => {
          this.waitAndGotoSignInPage();
        })
      )
      .subscribe((resp) => {
        this.isSecondaryEmailVerified = true;
      });
  }
}
