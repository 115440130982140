import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, RendererFactory2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TargetAppConfigService } from './target-app-config.service';
import { BehaviorSubject } from 'rxjs';
import { TokenService } from './token.service';
import { ROOT_URL } from '@fyle/root-url-token';

@Injectable({
  providedIn: 'root',
})
export class InitializationService {
  constructor(
    @Inject(ROOT_URL) private rootUrl: BehaviorSubject<string>,
    private tokenService: TokenService,
    private httpClient: HttpClient,
    @Inject(DOCUMENT) private document: Document,
    private rendererFactory: RendererFactory2,
    private title: Title,
    private targetAppConfigService: TargetAppConfigService,
  ) {}

  private updateClusterDomain() {
    const clusterDomain = this.tokenService.getClusterDomain();
    /* In case the `clusterDomain` doesn't exists in the local storage, the default value will be the
     * value of `environment.ROOT_URL`.
     */
    if (clusterDomain) {
      this.rootUrl.next(clusterDomain);
    }
  }

  private appendSvgSpriteToDocument() {
    this.httpClient.get('./assets/icons/icons-sprite.svg', { responseType: 'text' }).subscribe((result) => {
      const renderer = this.rendererFactory.createRenderer(null, null);
      const spriteElement = renderer.createElement('div');
      renderer.addClass(spriteElement, 'svg-sprite');
      spriteElement.innerHTML = result;
      renderer.insertBefore(this.document.body, spriteElement, this.document.getElementsByTagName('app-root')[0]);
    });
  }

  private setValuesBasedOnEnv() {
    const targetConfig = this.targetAppConfigService.getTargetConfig();
    this.title.setTitle(targetConfig.WEBPAGE_TITLE);
    this.document.getElementById('appFavicon').setAttribute('href', `${targetConfig.FAVICONS_PATH}/favicon-32x32.png`);
    this.document.documentElement.setAttribute('data-theme', targetConfig.TARGET);
  }

  initConfig() {
    this.updateClusterDomain();
    this.appendSvgSpriteToDocument();
    this.setValuesBasedOnEnv();
  }
}
