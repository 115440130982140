import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DisabledQueryParams } from './disabled-query-params.interface';
import { TargetAppConfig } from '../../core/models/target-app-config.model';
import { TargetAppConfigService } from '../../core/services/target-app-config.service';

@Component({
  selector: 'app-disabled',
  templateUrl: './disabled.component.html',
  styleUrls: ['./disabled.component.scss'],
})
export class DisabledComponent implements OnInit {
  emailId: string;

  targetConfig: TargetAppConfig;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private targetAppConfigService: TargetAppConfigService
  ) {}

  ngOnInit() {
    this.targetConfig = this.targetAppConfigService.getTargetConfig();
    const params = this.activatedRoute.snapshot.queryParams as DisabledQueryParams;
    this.emailId = params.email_id;
  }

  goToSignin() {
    this.router.navigate(['signin']);
  }
}
