import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { catchError, finalize, throwError } from 'rxjs';
import { InvitationRequestService } from '../../core/services/invitation-request.service';
import { RequestInvitationQueryParams } from './request-invitation-query-params.interface';
import { TargetAppConfigService, TargetAppConfig } from '@fyle/target-app-config';
import { ButtonType } from '@fyle/model-shared';

@Component({
  selector: 'app-request-invitation',
  templateUrl: './request-invitation.component.html',
  styleUrls: ['./request-invitation.component.scss'],
})
export class RequestInvitationComponent implements OnInit {
  isAPIInProgress: boolean;

  isRequestSent: boolean;

  canRequest: boolean;

  buttonType = ButtonType;

  private queryParams: RequestInvitationQueryParams;

  targetConfig: TargetAppConfig;

  constructor(
    private activatedRoute: ActivatedRoute,
    private invitationRequestService: InvitationRequestService,
    private targetAppConfigService: TargetAppConfigService,
  ) {}

  private reset() {
    this.canRequest = true;
    this.isRequestSent = false;
  }

  requestInvite() {
    this.isAPIInProgress = true;

    this.invitationRequestService
      .upsertRouter(this.queryParams.email)
      .pipe(
        catchError((error) => {
          this.canRequest = false;
          return throwError(() => error);
        }),
        finalize(() => {
          this.isAPIInProgress = false;
        }),
      )
      .subscribe((resp) => {
        this.isRequestSent = true;
      });
  }

  ngOnInit() {
    this.targetConfig = this.targetAppConfigService.getTargetConfig();
    this.queryParams = this.activatedRoute.snapshot.queryParams as RequestInvitationQueryParams;
    this.reset();

    if (!this.queryParams.email) {
      this.canRequest = false;
    }
  }
}
