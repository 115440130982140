import { Component, OnInit } from '@angular/core';
import { CapitaloneSignonQueryParams } from './capitalone-signon.interface';
import { Router, ActivatedRoute } from '@angular/router';
import { SignInResponse } from '../../core/models/sign-in-response.model';
import { PlatformAuthService } from '../../core/services/platform-auth.service';
import { catchError, finalize, throwError } from 'rxjs';
import { SignInErrorState } from '../sign-in/sign-in-error-state.enum';
import { UserStorageService } from '../../core/services/user-storage.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-capitalone-signon',
  templateUrl: './capitalone-signon.component.html',
  styleUrls: ['./capitalone-signon.component.scss'],
})
export class CapitaloneSignonComponent implements OnInit {
  showOverlay: boolean;

  header: string;

  signInErrorState: typeof SignInErrorState = SignInErrorState;

  errorState: SignInErrorState;

  descriptions: string[];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private platformAuthService: PlatformAuthService,
    private userStorageService: UserStorageService,
  ) {}

  private handleError(errorResponse: HttpErrorResponse) {
    this.errorState = this.signInErrorState.UNKNOWN_ERROR;
    const errorMessage = errorResponse.error?.message || 'Something Went Wrong';
    this.header = 'Signon failed';
    this.descriptions = [errorMessage];
  }

  /**
   * The API registers the user if unregistered and return the refresh_token and org_id.
   */
  private handleCapitalOneSignon() {
    this.showOverlay = true;
    const queryParams = this.activatedRoute.snapshot.queryParams as CapitaloneSignonQueryParams;
    /**
     * Remove `mockData` once the CapitalOne API's are ready
     */
    const mockData: Record<string, string> = {
      user_email: queryParams.email,
      org_name: queryParams.org_name,
      user_full_name: queryParams.username,
    };
    this.platformAuthService
      .capitalOneSignon(queryParams.authorization_code, queryParams.account_reference_id, mockData)
      .pipe(
        catchError((error) => {
          this.handleError(error);
          return throwError(() => error);
        }),
        finalize(() => (this.showOverlay = false)),
      )
      .subscribe((resp: SignInResponse) => {
        /**
         * Hiding onboarding dialog for CapitalOne POC.
         * CapitalOne customers don't need to configure Per diem, Mileage, Advances etc
         */
        this.userStorageService.set('hide_onboarding_dialog', true);
        this.router.navigate(['switch_org']);
      });
  }

  ngOnInit() {
    this.handleCapitalOneSignon();
  }
}
