import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RedirectionService } from './core/services/redirection.service';
import { TabStorageService } from './core/services/tab-storage.service';
import { UserEventService } from './core/services/user-event.service';
import { UserStorageService } from './core/services/user-storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private redirectionService: RedirectionService,
    private tabStorageService: TabStorageService,
    private window: Window,
    private userEventService: UserEventService,
    private router: Router,
    private userStorageService: UserStorageService,
  ) {}

  private onUserLogout() {
    this.router.navigate(['logout']);
  }

  /*
   * To check whether access_token, refresh_token and user has been removed from all
   * the org ids present in orgData or not.
   */
  private isUserLoggedIn() {
    const orgData = this.userStorageService.get('org_data');
    for (const orgId in orgData) {
      if (orgData[orgId].access_token && orgData[orgId].refresh_token && orgData[orgId].user) {
        this.tabStorageService.set('org_id', orgId);
        return true;
      }
    }
    return false;
  }

  private onStorageChange(event: StorageEvent) {
    const orgId = this.tabStorageService.get('org_id');
    /**
     * We are checking cluster_domain event to logout the user from all the tabs, because as we are retaing orgData,
     * so it becomes difficult to ensure that user has completely logged out on the basis of one event.
     * We need a combination of event and local storage keys to fully ensure that user has logged out.
     * So, We check for cluster_domain event key and also it should not be present in local storage and also check for
     * access_token, refresh_token and user key should not present in any of the orgId present in orgData in local storage.
     * Forcefully logout when the user opened mulitple tabs and logs out from one of the tabs.
     *
     * @link https://developer.mozilla.org/en-US/docs/Web/API/Window/storage_event
     */
    if (
      event.key === 'fyle.cluster_domain' &&
      !this.userStorageService.get('cluster_domain') &&
      !this.isUserLoggedIn()
    ) {
      this.redirectionService.goToAccountsApp('logout');
      return;
    }

    /*
     * Since we are retaining orgData, so we cannot decide on the basis of one event that user has logged in sucessfully.
     * And also deciding in which orgId user has loggedin is typical in current scenrio , so we are checking a few condition
     * before setting the orgId in tab storage , which can be seen in org-storage.service file.
     * So, On each event we check for cluster_domain should present in local storage and also check for
     * access_token, refresh_token and user key should present in any of the orgId present in orgData and since the user is
     * logging in so there should be no orgId present in tabStorage, We are checking this as well.
     */
    if (!orgId && this.userStorageService.get('cluster_domain') && this.isUserLoggedIn()) {
      this.window.location.reload();
      return;
    }
  }

  ngOnInit() {
    this.window.addEventListener('storage', this.onStorageChange.bind(this));
    this.userEventService.onLogout(this.onUserLogout.bind(this));
  }
}
