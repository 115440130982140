import { Injectable } from '@angular/core';
import { MessageService, Message } from 'primeng/api';
import { ToastActionConfig } from '../models/toast-action-config.model';

@Injectable({
  providedIn: 'root',
})
export class ToastMessageService {
  constructor(private messageService: MessageService) {}

  private showToastMessage(toastParams: Message, actionConfig?: ToastActionConfig) {
    /** clears any existing toast before showing a new toast */
    this.messageService.clear();

    const toastObj = {
      ...toastParams,
      life: actionConfig?.actionText ? 4000 : 3000,
      data: {
        actionText: actionConfig?.actionText,
        actionToDo: actionConfig?.callback,
      },
    };

    this.messageService.add(toastObj);
  }

  showSuccessToast(message: string, actionConfig?: ToastActionConfig) {
    const toastParam: Message = {
      severity: 'success',
      detail: message,
      icon: 'check-circle-outline',
    };

    this.showToastMessage(toastParam, actionConfig);
  }

  showErrorToast(message: string, actionConfig?: ToastActionConfig) {
    const toastParam: Message = {
      severity: 'error',
      detail: message,
      icon: 'fy-warning-o',
    };

    this.showToastMessage(toastParam, actionConfig);
  }

  showInfoToast(message: string, actionConfig?: ToastActionConfig) {
    const toastParam: Message = {
      severity: 'info',
      detail: message,
      icon: 'fy-info-circle-outline',
    };

    this.showToastMessage(toastParam, actionConfig);
  }
}
