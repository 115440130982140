import { Component, Input, OnInit } from '@angular/core';
import { TargetAppConfig } from '../../../core/models/target-app-config.model';
import { TargetAppConfigService } from '../../../core/services/target-app-config.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() iconUrl: string;

  constructor(private targetAppConfigService: TargetAppConfigService) {}

  ngOnInit() {
    const targetConfig = this.targetAppConfigService.getTargetConfig();
    // if logo url is not passed from parent, then set default logo url
    if (!this.iconUrl) {
      this.iconUrl = targetConfig.FULL_LOGO_URL_BLACK;
    }
  }
}
