import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-localstorage';

@Injectable({
  providedIn: 'root',
})
export class UserStorageService {
  constructor(private localStorageService: LocalStorageService) {}

  set(key: string, value: any) {
    this.localStorageService.set(key, value);
  }

  get(key: string): any {
    return this.localStorageService.get(key);
  }

  remove(key: string) {
    this.localStorageService.remove(key);
  }

  removeAll() {
    this.localStorageService.clear();
  }

  removeKeysOnLogout() {
    const userLevelLocalStorageKeys = ['cluster_domain', 'last_logged_in_org_queue'];
    for (const key of userLevelLocalStorageKeys) {
      this.localStorageService.remove(key);
    }
  }
}
