import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { SwitchViewService } from './switch-view.service';

@Injectable({
  providedIn: 'root',
})
export class RedirectionService {
  constructor(private window: Window, private switchViewService: SwitchViewService) {}

  goToAdminApp(path: string = 'admin_tasks') {
    const isAllowed = this.switchViewService.changeView('company');
    if (isAllowed) {
      const url = `${environment.APP_URL}/admin/#/${path}`;
      this.handleRedirection(url);
    }
  }

  goToMainApp(path: string = 'landing') {
    const isAllowed = this.switchViewService.changeView('individual');

    if (isAllowed) {
      const url = `${environment.APP_URL}/main/#/${path}`;
      this.handleRedirection(url);
    }
  }

  goToAccountsApp(path = 'signin') {
    const url = `${environment.APP_URL}/accounts/#/${path}`;
    this.handleRedirection(url);
  }

  handleRedirection(url: string, replace = false, event?: KeyboardEvent) {
    if (event?.ctrlKey || event?.metaKey) {
      this.window.open(url, '_blank');
    } else if (replace) {
      this.window.location.replace(url);
    } else {
      this.window.location.href = url;
    }
  }
}
