import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { SignInResponse } from '../models/sign-in-response.model';
import { CapitaloneSignonPayload } from '../models/capitalone-signon-payload.model';
import { PlatformApiService } from './platform-api.service';
import { RouterAuthService } from './router-auth.service';
import { CapitaloneSignonResponse } from '../models/capitalone-signon-response.model';

@Injectable({
  providedIn: 'root',
})
export class PlatformAuthService {
  constructor(
    private platformApiService: PlatformApiService,
    private routerAuthService: RouterAuthService,
  ) {}

  capitalOneSignon(
    authorizationCode: string,
    accountReferenceId: string,
    mockData: Record<string, string>,
  ): Observable<SignInResponse> {
    const payload: CapitaloneSignonPayload = {
      authorization_code: authorizationCode,
      account_reference_id: accountReferenceId,
      /**
       * The mock_account_data is added to mock the flow. Remove it once the APIs are ready from CapitalOne.
       */
      mock_account_data: mockData,
    };

    return this.platformApiService
      .post<CapitaloneSignonResponse>('/partner_capitalone/signon', payload)
      .pipe(switchMap((resp) => this.routerAuthService.handleSignInResponse(resp.data)));
  }
}
