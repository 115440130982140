import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { AuthRoutingModule } from './auth-routing.module';
import { DisabledComponent } from './disabled/disabled.component';
import { GoogleSignInComponent } from './google-sign-in/google-sign-in.component';
import { LogoutComponent } from './logout/logout.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { PendingVerificationComponent } from './pending-verification/pending-verification.component';
import { RequestInvitationComponent } from './request-invitation/request-invitation.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SecondaryEmailComponent } from './secondary-email/secondary-email.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignupComponent } from './signup/signup.component';
import { SwitchOrgComponent } from './switch-org/switch-org.component';
import { VerifyComponent } from './verify/verify.component';
import { CapitaloneSignonComponent } from './capitalone-signon/capitalone-signon.component';

@NgModule({
  declarations: [
    DisabledComponent,
    GoogleSignInComponent,
    LogoutComponent,
    NewPasswordComponent,
    PendingVerificationComponent,
    RequestInvitationComponent,
    ResetPasswordComponent,
    SecondaryEmailComponent,
    SignInComponent,
    SignupComponent,
    VerifyComponent,
    SwitchOrgComponent,
    CapitaloneSignonComponent,
  ],
  imports: [SharedModule, AuthRoutingModule],
})
export class AuthModule {}
