import { Injectable } from '@angular/core';
import { UserRole } from '../models/user-role.enum';
import { AuthService } from './auth.service';
import { OrgStorageService } from './org-storage.service';

@Injectable({
  providedIn: 'root',
})
export class SwitchViewService {
  constructor(private authService: AuthService, private orgStorageService: OrgStorageService) {}

  allowedCompanyView(): boolean {
    const roles = this.authService.getRoles();
    const possibleAdminRoles = [
      UserRole.ADMIN,
      UserRole.FINANCE,
      UserRole.VERIFIER,
      UserRole.PAYMENT_PROCESSOR,
      UserRole.AUDITOR,
    ];

    return roles.some((role) => possibleAdminRoles.includes(role));
  }

  allowedIndividualView(): boolean {
    const roles = this.authService.getRoles();
    const possibleIndividualRoles = [UserRole.FYLER, UserRole.APPROVER];

    return roles.some((role) => possibleIndividualRoles.includes(role));
  }

  getCurrentView(): string {
    let view = this.orgStorageService.get('current_view');
    if (!view) {
      const roles = this.authService.getRoles();
      if (!roles.includes(UserRole.FYLER) && !roles.includes(UserRole.APPROVER)) {
        view = 'company';
      } else {
        view = 'individual';
      }
    }
    return view;
  }

  setCurrentView(routeView: string) {
    if (this.getCurrentView() !== routeView) {
      this.orgStorageService.set('current_view', routeView);
    }
  }

  isIndividualView(): boolean {
    return this.getCurrentView() === 'individual';
  }

  changeView(route: string): boolean {
    let allowed = false;
    if (this.getCurrentView() === route) {
      allowed = true;
    } else if (route === 'individual' && this.allowedIndividualView()) {
      allowed = true;
    } else if (route === 'company' && this.allowedCompanyView()) {
      allowed = true;
    }

    if (allowed) {
      this.setCurrentView(route);
    }
    return allowed;
  }
}
