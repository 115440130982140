import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalSize } from './modal-size.enum';

@Component({
  selector: 'ui-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class FdlModalComponent {
  @Input() size: ModalSize = ModalSize.SMALL;
}
