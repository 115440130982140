import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SvgSpriteComponent } from '@fyle/svg-sprite';

@Directive({
  selector: '[fylAppFormValidationButtonLoader]',
  standalone: true,
})
export class FormValidationButtonLoaderDirective implements OnChanges {
  @Input() loadingText: string;

  @Input() isLoading: boolean;

  @Input() formToValidate: FormGroup;

  @Input() formElement: HTMLFormElement;

  private initialButtonHTML: string;

  private isLoaderAdded: boolean;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    private viewContainerRef: ViewContainerRef,
  ) {}

  private setButtonLoader(): void {
    const componentRef = this.viewContainerRef.createComponent(SvgSpriteComponent);
    componentRef.instance.icon = 'loader';
    componentRef.instance.classes = 'tw-h-12-px tw-w-12-px tw-ml-8-px tw-animate-spinner';

    const iconFillColor = this.element.nativeElement.firstChild.outerHTML.includes('outline-secondary')
      ? 'tw-fill-icon-disable'
      : 'tw-fill-white';
    componentRef.instance.classes += ` ${iconFillColor}`;

    componentRef.instance.containerClasses = 'tw-h-14-px';
    this.renderer.appendChild(this.element.nativeElement.firstChild, componentRef.location.nativeElement);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.loadingText?.previousValue) {
      this.element.nativeElement.firstChild.innerHTML = changes.loadingText.currentValue;
      this.setButtonLoader();
    }
    if (this.formToValidate?.touched) {
      this.verifyFormAndContinue();
    } else {
      this.isLoading ? this.addLoader() : this.removeLoader();
    }
  }

  @HostListener('click', ['$event'])
  @HostListener('keydown.enter', ['$event'])
  onClick(): void {
    if (this.formToValidate) {
      this.verifyFormAndContinue();
    } else {
      this.isLoading ? this.addLoader() : this.removeLoader();
    }
  }

  verifyFormAndContinue(): void {
    if (this.isFormValid(this.formToValidate, this.formElement)) {
      this.isLoading ? this.addLoader() : this.removeLoader();
    } else {
      return;
    }
  }

  isFormValid(formGroup: FormGroup, formElement: HTMLElement): boolean {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control = formGroup.get(key);
        if (control instanceof FormGroup) {
          // Recursively validating nested form groups
          if (!this.isFormValid(control, formElement)) {
            return false;
          }
        } else {
          const formControlElement: HTMLElement = formElement.querySelector(`[formControlName="${key}"]`);
          /**
           * Validates a form by iterating through its controls.
           *
           * Note:
           * 1. Works only when controls are shown/hidden using Angular's *ngIf directive.
           * 2. If controls are hidden using CSS display: none, this validation logic won't work as intended.
           *
           * This function performs the following tasks:
           * - Checks if the form control element has any validation errors.
           * - If errors are found, the control is marked as touched and focused.
           */
          if (formControlElement && control.errors) {
            control.markAsTouched();
            control.markAsDirty();
            formControlElement.focus();
            return false;
          }
        }
      }
    }
    return true;
  }

  addLoader() {
    if (this.isLoaderAdded) {
      return;
    }

    this.initialButtonHTML = this.element.nativeElement.firstChild.innerHTML;
    this.element.nativeElement.firstChild.innerHTML = this.loadingText || this.initialButtonHTML;

    this.renderer.setAttribute(this.element.nativeElement.firstChild, 'disabled', 'true');
    this.setButtonLoader();

    this.isLoaderAdded = true;
  }

  removeLoader() {
    if (this.isLoaderAdded) {
      this.renderer.removeAttribute(this.element.nativeElement.firstChild, 'disabled');
      this.element.nativeElement.firstChild.innerHTML = this.initialButtonHTML;
      this.viewContainerRef.clear();
      this.isLoaderAdded = false;
    }
  }
}
