<div class="custom-dialog-popup">

  <app-dialog-header [title]="title" [titleTheme]="titleTheme">
  </app-dialog-header>

  <div>

    <div class="dialog-content">

      @if (consequences?.length > 0) {
        <ul class="consequences-msg">
          @for (consequence of consequences; track consequence) {
            <li class="consequence-msg">
              {{consequence}}
            </li>
          }
        </ul>
      }

      @if (isPrompt) {
        <div class="input-container">
          <div class="tw-flex tw-flex-row">
            <label class="input-label">{{label}}</label>
            @if (required) {
              <span class="asterisk">*</span>
            }
          </div>
          @if (inputType === 'textarea') {
            <textarea
              class="prompt-input"
              [ngClass]="prompt.touched && prompt.invalid ? 'input-error' : ''"
              appAutofocus
              [formControl]="prompt"
              [placeholder]="placeholder"
              rows="3"
              max-rows="4"
              >
            </textarea>
          }
          @if (!inputType || inputType === 'input') {
            <input
              class="prompt-input"
              [ngClass]="prompt.touched && prompt.invalid ? 'input-error' : ''"
              appAutofocus
              [formControl]="prompt"
              [placeholder]="placeholder"
              >
          }
          @if (prompt.touched && prompt.invalid) {
            @if (prompt.hasError('required')) {
              <div class="input-error-message">{{requiredErrorMessage}}</div>
            }
            @if (prompt.hasError('maxlength')) {
              <div class="input-error-message">{{lengthErrorMessage}}</div>
            }
          }
        </div>
      }

      <div class="confirmation-msg" [ngClass]="{'!tw-m-0' : !consequences || consequences.length === 0 || inputType === 'input' }" [innerHTML]="confirmationMsg"></div>

    </div>

    <div class="button-actions-block">
      @if (type !== dialogTypes.ALERT && cancelButtonText) {
        <ui-button
          [defaultType]="'button'"
          (buttonClick)="close('cancel')"
          [buttonType]="buttonType.OUTLINE_SECONDARY"
          [label]="cancelButtonText">
        </ui-button>
      }

      <ui-button
        [defaultType]="'submit'"
        (buttonClick)="hide()"
        [buttonType]="buttonType.PRIMARY"
        [customClasses]="'tw-ml-spacing-16'"
        [label]="okButtonText">
      </ui-button>
    </div>
  </div>
</div>