import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const appRoutes: Routes = [
  {
    path: 'pre_verification',
    loadChildren: () => import('./pre-verification/pre-verification.module').then((m) => m.PreVerificationModule),
  },
  {
    path: 'post_verification',
    loadChildren: () => import('./post-verification/post-verification.module').then((m) => m.PostVerificationModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { useHash: true, bindToComponentInputs: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
