<div class="sign-in auth-container">
  <div class="card-container">
    <app-header></app-header>
    <div class="auth-card">
      <h3 class="title">Sign In</h3>
      <p class="sub-title">Please enter your credentials to access your account</p>

      <form #signInFormElement [formGroup]="signInForm" novalidate class="sign-in-form">

        @if (errorState && errorState !== signInErrorState.ACCOUNT_LOCKED && doesEmailExist) {
          @if (errorState === signInErrorState.UNAUTHORIZED && doesEmailExist) {
            <ui-alert class="tw-mb-spacing-16" [type]="alertTypes.WARNING" [header]="'Account will be locked after 5 unsuccessful attempts'" [message]="'Your account will be temporarily locked.'"></ui-alert>
          }
          @if (errorState === signInErrorState.UNKNOWN_ERROR) {
            <ui-alert class="tw-mb-spacing-16" [type]="alertTypes.WARNING" [message]="'Something went wrong, please try again later.'"></ui-alert>
          }
        }

        @if (errorState === signInErrorState.ACCOUNT_LOCKED) {
          <ui-alert class="tw-mb-spacing-16" [type]="alertTypes.ERROR" [header]="'Try logging in after 5 minutes'" [message]="'This account is temporarily locked due to multiple unsuccessful login attempts.'"></ui-alert>
        }

        @if (errorState === signInErrorState.GOOGLE_ACCESS_DENIED) {
          <ui-alert class="tw-mb-spacing-16" [type]="alertTypes.ERROR" [header]="'Google Account Access Denied'" [message]="'Allow access to your google account to sign in with Google.'"></ui-alert>
        }

        <div class="email-input-container">
          <div class="tw-flex tw-flex-row">
            <label class="input-label">Work Email</label>
            <span class="asterisk">*</span>
          </div>
          <div class="email-input tw-relative">
            <input
              formControlName="email"
              type="email"
              name="email"
              appAutofocus
              placeholder="Enter your business email here"
              class="input-content input-text"
              [ngClass]="{'p-error' : signInForm.controls.email.touched && signInForm.controls.email.invalid }"
              >

            @if (!doesEmailExist) {
              <div class="error-space">
              </div>
            }

            @if (signInForm.controls.email.valid && !doesEmailExist && !showNoEmailError) {
              <div class="action-wrapper">
                <span class="action-text">
                  <app-svg-sprite icon="check" classes="common-icon tw-fill-white tw-bg-sea-green tw-rounded-1/2 tw-p-4-px"></app-svg-sprite>
                </span>
              </div>
            }

            @if (doesEmailExist) {
              <div class="action-wrapper">
                <span class="action-text">
                  <span class="change-text" (click)="onEmailChange()">Change</span>
                </span>
              </div>
            }

            @if (showNoEmailError && !doesEmailExist) {
              <div class="action-wrapper">
                <span class="action-text">
                  <app-svg-sprite icon="danger-fill" classes="common-icon tw-fill-fire-engine-red tw-bg-white tw-rounded-1/2"></app-svg-sprite>
                </span>
              </div>
            }

            @if (signInForm.controls.email.touched && signInForm.controls.email.errors?.pattern) {
              <div class="action-wrapper">
                <span class="action-text">
                  <app-svg-sprite icon="danger-fill" classes="tw-p-0 common-icon tw-fill-fire-engine-red"></app-svg-sprite>
                </span>
              </div>
            }

          </div>


          @if (signInForm.controls.email.touched && signInForm.controls.email.errors) {
            @if (signInForm.controls.email.hasError('required')) {
              <div class="error">Please enter an email address</div>
            }
            @if (signInForm.controls.email.hasError('pattern')) {
              <div class="error">Please enter a valid email address</div>
            }
          }

          @if (errorState === signInErrorState.UNKNOWN_ERROR && !doesEmailExist) {
            <ui-alert class="tw-my-spacing-8 tw-grid" [ngClass]="{'tw-animate-blinker': showNoEmailError}" [type]="alertTypes.INFORMATION" [message]="'Something went wrong, please try again later.'"></ui-alert>
          }

        </div>

        @if (!doesEmailExist) {
          <ui-button
            [validateForm]="true"
            [isLoading]="showLoaderOnButton"
            [loadingText]="'Validating'"
            [formToValidate]="signInForm"
            [formElement]="signInFormElement"
            [defaultType]="'submit'"
            [buttonType]="buttonType.PRIMARY"
            [customClasses]="'button-full padding-14 font-700'"
            (buttonClick)="checkIfEmailExists()"
            [label]="'Next'"
            >
          </ui-button>
        }

        @if (doesEmailExist) {
          <div class="password-container tw-mt-10-px">
            <div class="password-label tw-flex tw-items-end tw-justify-between">
              <div class="tw-flex tw-flex-row">
                <label class="input-label">Password</label>
                <span class="asterisk">*</span>
              </div>
              <p class="forgot-password" (click)="goToResetPassword()">Forgot password?</p>
            </div>
            <div class="password-input tw-relative">
              <input
                formControlName="password"
                appAutofocus
                autocomplete="current-password"
                type="{{showPlainPassword ? 'text' : 'password'}}"
                [ngClass]="{'p-error' : signInForm.controls.password.touched && signInForm.controls.password.invalid}"
                placeholder="Enter your password here"
                class="input-content input-text"
                >
              <div class="error-space">
              </div>
              @if (errorState === signInErrorState.UNAUTHORIZED && doesEmailExist && showNoEmailError) {
                <div class="action-wrapper info-wrapper tw-mr-32-px">
                  <span class="action-text">
                    <app-svg-sprite icon="danger-fill" classes="tw-p-0 tw-p-0 common-icon tw-fill-fire-engine-red"></app-svg-sprite>
                  </span>
                </div>
              }
              @if (!showPlainPassword) {
                <div class="action-wrapper hide-icon" (click)="showPlainPassword = !showPlainPassword">
                  <span class="action-text">
                    <app-svg-sprite icon="eye-slash" classes="tw-p-0 common-icon tw-cursor-pointer"></app-svg-sprite>
                  </span>
                </div>
              }
              @if (showPlainPassword) {
                <div class="action-wrapper view-eye-icon" (click)="showPlainPassword = !showPlainPassword">
                  <span class="action-text">
                    <app-svg-sprite icon="eye" classes="tw-p-0 common-icon tw-cursor-pointer"></app-svg-sprite>
                  </span>
                </div>
              }
              @if (errorState === signInErrorState.UNAUTHORIZED && doesEmailExist && showNoEmailError) {
                <div class="no-email-err error">
                  The password is incorrect
                </div>
              }
              @if (signInForm.controls.password.touched && signInForm.controls.password.errors) {
                @if (signInForm.controls.password.hasError('required')) {
                  <div class="error">Please enter your password</div>
                }
              }
            </div>
            <ui-button
              [validateForm]="true"
              [isLoading]="isSignInApiInProgress"
              [loadingText]="'Signing In'"
              [defaultType]="'submit'"
              [buttonType]="buttonType.PRIMARY"
              [customClasses]="'button-full font-700 padding-14'"
              (buttonClick)="basicAuthSignin()"
              [label]="'Sign in'">
            </ui-button>
          </div>
        }

        @if (!hideGoogleLogin) {
          <div class="or-text-container">
            <span class="or-text">
              Or
            </span>
          </div>
        }

        @if (!hideGoogleLogin) {
          <ui-button
            defaultType="submit"
            customClasses="button-full button-google"
            [buttonType]="buttonType.OUTLINE_SECONDARY"
            (buttonClick)="continueWithGoogle()"
            [label]="'Sign in with Google'"
            [customPrefixTemplate]="iconTemplate"
            >
          </ui-button>
        }

        <ng-template #iconTemplate>
          <div class="tw-rounded-tr-border-radius-2xs tw-rounded-bl-border-radius-2xs">
            <img
              src="./assets/images/google-logo-36x36.png"
              aria-label="Sign in with Google"
              alt="Google"
              class="tw-h-24-px tw-w-24-px tw-mr-spacing-8"
              >
          </div>
        </ng-template>

        @if (!isSignInApiInProgress) {
          <div class="signup-text">New to {{targetConfig.BRAND_NAME}}? <a class="tw-text-navy-blue" href="https://www.fylehq.com/schedule-demo">Schedule a Demo</a>
        </div>
      }

    </form>
  </div>
</div>
<footer class="footer">Never lose track</footer>
</div>
