import { Component, ContentChild, OnInit, TemplateRef } from '@angular/core';
import { FdlModalComponent } from '../modal/modal.component';
import { FdlModalActionsComponent } from '../modal-actions/modal-actions.component';
import { FdlModalContentComponent } from '../modal-content/modal-content.component';
import { FdlModalHeaderComponent } from '../modal-header/modal-header.component';
import { ModalHeaderTypes } from '../modal-header/modal-header-type.enum';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmationModalConfig } from './confirmation-modal.model';
import { CommonModule } from '@angular/common';
import { ModalSize } from '../modal/modal-size.enum';

@Component({
  selector: 'ui-confirmation-modal',
  standalone: true,
  imports: [
    CommonModule,
    FdlModalComponent,
    FdlModalActionsComponent,
    FdlModalContentComponent,
    FdlModalHeaderComponent,
  ],
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements OnInit {
  modalHeaderType = ModalHeaderTypes;

  modalConfiguration: ConfirmationModalConfig;

  modalSize = ModalSize;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customTemplate: TemplateRef<any>;

  defaultConfirmationMessage = 'Would you like to continue?';

  constructor(
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
  ) {}

  ngOnInit() {
    this.modalConfiguration = this.dialogConfig.data;
    this.setCustomTemplate();
  }

  setCustomTemplate() {
    if (this.modalConfiguration.content instanceof TemplateRef) {
      this.customTemplate = this.modalConfiguration.content;
    }
  }

  close() {
    this.dialogRef.close();
  }
}
