import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserEventService {
  private logoutSubject = new Subject();

  constructor() {}

  onLogout(callback: () => void): Subscription {
    return this.logoutSubject.subscribe(callback);
  }

  logout() {
    this.logoutSubject.next('logout');
  }
}
