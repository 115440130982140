import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class LoggingHeaderInterceptor implements HttpInterceptor {
  constructor() {}

  getUrlwithoutQueryParam(urlString: string) {
    return urlString.split('?')[0];
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(
      request.clone({
        setHeaders: {
          'X-Page-Url': this.getUrlwithoutQueryParam(window.location.href),
          'X-Source-Identifier': 'webapp',
        },
      }),
    );
  }
}
