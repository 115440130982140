import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-intermediate-loader',
  templateUrl: './intermediate-loader.component.html',
  styleUrls: ['./intermediate-loader.component.scss'],
})
export class IntermediateLoaderComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
