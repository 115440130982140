import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-toast-message',
  templateUrl: './toast-message.component.html',
  styleUrls: ['./toast-message.component.scss'],
})
export class ToastMessageComponent implements OnInit {
  constructor(private messageService: MessageService) {}

  ngOnInit() {}

  onClose() {
    this.messageService.clear();
  }
}
