import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class B64CodecService {
  constructor() {}

  encode(params: Record<string, string>): string {
    const encoded = btoa(JSON.stringify(params));
    return encoded;
  }

  decode<T>(str: string): T {
    const decoded = JSON.parse(atob(str));
    return decoded;
  }
}
