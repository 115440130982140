<div class="active-org-card">
  <div class="card-container">
    <div class="card-header">
      Active Organization
    </div>
    @if (!isLoading) {
      <div fdlTooltip="Continue with Active Org" tooltipDirection="bottom"
        class="org-info-container tw-relative" (click)="onOrgSelection()">
        <div class="flex-row-center">
          <div class="currency-icon">
            <span class="currency">{{ activeOrg.currency }}</span>
            <app-svg-sprite class="check-icon flex-row-center" [icon]="'check'" classes="icon-org-button-badge tw-fill-white"></app-svg-sprite>
          </div>
          <div class="org-details">
            <div class="org-header">{{ activeOrg.name }}</div>
            <div class="org-domain">{{ activeOrg.domain }}</div>
          </div>
        </div>
        @if (isPrimary) {
          <div class="primary-text">Primary Org</div>
        }
      </div>
    }

    @if (isLoading) {
      <div class="org-info-container" >
        <div class="flex-row-center">
          <div class="currency-icon-shimmer">
            <ui-shimmers [height]="52" class="currency-icon-shimmer"></ui-shimmers>
            <ui-shimmers class="check-icon-shimmer flex-row-center"></ui-shimmers>
          </div>
          <div class="org-details">
            <ui-shimmers class="org-header" [height]="18" [width]="200"></ui-shimmers>
            <ui-shimmers class="org-domain" [height]="18" [width]="120"></ui-shimmers>
          </div>
        </div>
      </div>
    }
  </div>
</div>