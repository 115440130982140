import { Injectable } from '@angular/core';
import { OrgStorageService } from './org-storage.service';
import { UserStorageService } from './user-storage.service';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  constructor(private orgStorageService: OrgStorageService, private userStorageService: UserStorageService) {}

  getAccessToken(): string {
    return this.orgStorageService.get('access_token');
  }

  getRefreshToken(): string {
    return this.orgStorageService.get('refresh_token');
  }

  setAccessToken(accessToken: string) {
    this.orgStorageService.set('access_token', accessToken);
  }

  setRefreshToken(refreshToken: string) {
    this.orgStorageService.set('refresh_token', refreshToken);
  }

  setClusterDomain(clusterDomain: string) {
    this.userStorageService.set('cluster_domain', clusterDomain);
  }

  getClusterDomain(): string {
    return this.userStorageService.get('cluster_domain');
  }

  resetAccessToken() {
    this.orgStorageService.remove('access_token');
  }

  resetClusterDomain() {
    this.userStorageService.remove('cluster_domain');
  }

  resetRefreshToken() {
    this.orgStorageService.remove('refresh_token');
  }
}
