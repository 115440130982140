<div class="loading-overlay">
  <div class="loader">
    <div class="loader-bar"></div>
    <div class="loader-bar"></div>
    <div class="loader-bar"></div>
    <div class="loader-bar"></div>
    <div class="loader-bar"></div>
    <div class="loader-ball"></div>
  </div>
  <div class="message">
    <ng-content></ng-content>
  </div>
</div>
