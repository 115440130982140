import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { ExtendedOrgUser } from '../models/extended-org-user.model';
import { UserRole } from '../models/user-role.enum';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class OrgUserService {
  constructor(private apiService: ApiService, private authService: AuthService) {}

  markActive(): Observable<ExtendedOrgUser> {
    return this.apiService.post('/orgusers/current/mark_active').pipe(switchMap(() => this.authService.refreshEou()));
  }

  /**
   * Used mainly to decide what should be shown to the user on the admin dashboard
   * and where the user should be redirected.
   * The admin roles are given preference over approver and fyler roles. Also, there is a
   * precedence within the admin roles loosely defined on the extent of access they have
   * in the organization view.
   * But this function returns the role, independent of the view (company or individual) the
   * user is in. So, use it cautiously.
   *
   */
  getPrimaryRole(): UserRole {
    const roles = this.authService.getRoles();
    let userRole = UserRole.FYLER;

    if (roles.includes(UserRole.ADMIN)) {
      userRole = UserRole.ADMIN;
    } else if (roles.includes(UserRole.FINANCE)) {
      userRole = UserRole.FINANCE;
    } else if (roles.includes(UserRole.PAYMENT_PROCESSOR)) {
      userRole = UserRole.PAYMENT_PROCESSOR;
    } else if (roles.includes(UserRole.VERIFIER)) {
      userRole = UserRole.VERIFIER;
    } else if (roles.includes(UserRole.AUDITOR)) {
      userRole = UserRole.AUDITOR;
    } else if (roles.includes(UserRole.APPROVER)) {
      userRole = UserRole.APPROVER;
    } else if (roles.includes(UserRole.FYLER)) {
      userRole = UserRole.FYLER;
    }
    return userRole;
  }
}
