import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InvitationRequestPayload } from '../models/invitation-request-payload.model';
import { InvitationRequestResponse } from '../models/invitation-request-response';
import { RouterApiService } from './router-api.service';

@Injectable({
  providedIn: 'root',
})
export class InvitationRequestService {
  constructor(private routerApiService: RouterApiService) {}

  upsertRouter(email: string): Observable<InvitationRequestResponse> {
    const params: InvitationRequestPayload = {
      email,
    };
    return this.routerApiService.post<InvitationRequestResponse>('/invitation_requests/invite', params);
  }
}
