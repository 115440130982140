export enum UserRole {
  ADMIN = 'ADMIN',
  FYLER = 'FYLER',
  VERIFIER = 'VERIFIER',
  PAYMENT_PROCESSOR = 'PAYMENT_PROCESSOR',
  FINANCE = 'FINANCE',
  APPROVER = 'APPROVER',
  AUDITOR = 'AUDITOR',
  OWNER = 'OWNER',
  TRANSCRIBER = 'TRANSCRIBER',
}
