<div class="auth-container">

  <div class="card-container">

    <app-header></app-header>

    <div class="auth-card">
      @if (!isSecondaryEmailVerified && !isError) {
        <p>Please wait while we verify your email...</p>
      }

      @if (isSecondaryEmailVerified) {
        <p>Your email address has been successfully verified.</p>
      }
      @if (isError) {
        <p>Something went wrong while verifying your email address.</p>
      }
      @if (isSecondaryEmailVerified || isError) {
        <p>Please wait while you are redirected...</p>
      }

      <p-progressSpinner strokeWidth="5" styleClass="spinner-50"></p-progressSpinner>

    </div>
  </div>
</div>
