import { AbstractControl, ValidationErrors } from '@angular/forms';

export class ComparePassword {
  static mustMatch(control: AbstractControl): ValidationErrors | null {
    const newPassword = control.get('newPassword');
    const confirmPassword = control.get('confirmPassword');
    if (newPassword.value && newPassword.value !== confirmPassword.value) {
      return { mismatch: true };
    }
    return null;
  }
}
